// Generated by Framer (21ec140)

import { addFonts, cx, CycleVariantState, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ikuztL8hf"];

const serializationHash = "framer-mk0EC"

const variantClassNames = {ikuztL8hf: "framer-v-1ngqo0z"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transition2 = {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const animation = {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1.02, skewX: 0, skewY: 0, transition: transition2}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ikuztL8hf", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://framer.com/projects/new?duplicate=yX5upib5vaXwx8LVB1zm&via=cristianmielu"} openInNewTab><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1ngqo0z", className, classNames)} framer-7e252c`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ikuztL8hf"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.1)", ...style}}><motion.div className={"framer-s9z589"} data-framer-name={"Blur"} layoutDependency={layoutDependency} layoutId={"SpOYJXaCC"} style={{backgroundColor: "rgba(255, 255, 255, 0.5)", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, filter: "blur(5px)", opacity: 0, WebkitFilter: "blur(5px)"}}/><motion.button className={"framer-oxio28"} data-reset={"button"} layoutDependency={layoutDependency} layoutId={"JeknWjrYL"} style={{backgroundColor: "var(--token-63e45272-007b-437e-b2a8-9f4d1433e3e9, rgb(102, 112, 255))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10}} whileHover={animation}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mk0EC.framer-7e252c, .framer-mk0EC .framer-7e252c { display: block; }", ".framer-mk0EC.framer-1ngqo0z { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 36px; justify-content: center; overflow: visible; padding: 12px; position: relative; text-decoration: none; width: 142px; }", ".framer-mk0EC .framer-s9z589 { bottom: 0px; flex: none; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; will-change: var(--framer-will-change-override, transform); z-index: 1; }", ".framer-mk0EC .framer-oxio28 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 36px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 142px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-mk0EC.framer-1ngqo0z, .framer-mk0EC .framer-oxio28 { gap: 0px; } .framer-mk0EC.framer-1ngqo0z > *, .framer-mk0EC .framer-oxio28 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-mk0EC.framer-1ngqo0z > :first-child, .framer-mk0EC .framer-oxio28 > :first-child { margin-left: 0px; } .framer-mk0EC.framer-1ngqo0z > :last-child, .framer-mk0EC .framer-oxio28 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 142
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerrpJ5mw6ag: React.ComponentType<Props> = withCSS(Component, css, "framer-mk0EC") as typeof Component;
export default FramerrpJ5mw6ag;

FramerrpJ5mw6ag.displayName = "Get Template Button";

FramerrpJ5mw6ag.defaultProps = {height: 36, width: 142};

addFonts(FramerrpJ5mw6ag, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})